/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Children, cloneElement, createContext, useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useUUID } from '@medifind/router';
import { Button } from '@medifind/shared-basic-components';
import { isBreakpointDown } from '@medifind/utils';
import styles from './FilterShared.module.scss';
export const FilterContext = createContext({ onClickSmall: null });

const Filter = ({
  className,
  children,
  headerChildren,
  noHeader = false,
  classes = {},
  style = {},
  what,
  label,
  onClear,
  smallAt,
  placeholder,
  showWrapper = true,
  isChanged = true,
  hideReset,
}) => {
  const labelId = useUUID();
  const { onClickSmall } = useContext(FilterContext);
  return (
    <div
      style={style.filter}
      className={classNames({ [styles['filter']]: showWrapper }, styles[`filter--${smallAt}`], className, classes.filter)}
      onClick={() => {
        if (isBreakpointDown(smallAt)) {
          onClickSmall && onClickSmall();
        }
      }}
      // TODO: When small should be tab-able and show a focus
      // onKeyPress={e => {
      //   if (e.key === 'Enter' && isBreakpointDown(smallAt) && onClickSmall) onClickSmall();
      // }}
      // tabIndex="0"
      // role="button"
    >
      {/* <span className={styles['focus-container']}> */}
      {!noHeader && (
        <div style={style.header} className={classNames(styles['header'], classes.header)}>
          <span style={style.label} id={labelId} className={classNames(styles['label'], classes.label)}>
            {label}
          </span>
          <span style={style.what} className={classNames(styles['what'], classes.what)}>
            {what}
          </span>
          {headerChildren}
          {/* {onClear && ( */}
          {!hideReset && onClear && (
            <Button
              onClick={onClear}
              disabled={!isChanged}
              focus={'underline'}
              color={'transparent'}
              label={'Reset'}
              type={'thin'}
              style={{ container: { marginLeft: 'auto' } }}
              className={classNames(styles['clear-button'], { [styles['clear-button--disabled']]: !isChanged })}
            />
          )}
          {/* )} */}
        </div>
      )}
      {smallAt && (
        <div style={style.placeholder} className={classNames(styles['placeholder'], classes.placeholder)}>
          <div>{placeholder}</div>
        </div>
      )}
      <div className={classNames(styles['children'], classes.children)}>
        {Children.map(children, (child) => child && cloneElement(child, { labelId: noHeader ? null : labelId }))}
      </div>
      {/* </span> */}
    </div>
  );
};

Filter.propTypes = {
  what: PropTypes.node,
  classes: PropTypes.object,
  style: PropTypes.object,
  children: PropTypes.any,
  headerChildren: PropTypes.any,
  label: PropTypes.string,
  onClickSmall: PropTypes.func,
  onClear: PropTypes.func,
  className: PropTypes.string,
  smallAt: PropTypes.string,
  noHeader: PropTypes.bool,
  selectValue: PropTypes.object,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  showWrapper: PropTypes.bool,
  isChanged: PropTypes.bool,
  hideReset: PropTypes.bool,
};

export { Filter as default, Filter };
